import React, { useState, useEffect } from 'react';
import { Button, Table, Input } from 'reactstrap';

export const Buscador = (props) => {
    const [nombre,setNombre] = useState("");
    const [datos,setDatos] = useState([]);
    const [tema,setTema] = useState("buscador");
    useEffect(() => {
        let tema = props.tema;
        if(tema === undefined)
           tema = "buscador";
        setTema(tema);

        const estado = localStorage.getItem(tema);
        if(estado !== null){
            const edo = JSON.parse(estado);
            setNombre(edo.nombre);
            setDatos(edo.datos);
        }
    },[props.tema]);
    const onKeyPress = e => {
        if (e.which === 13)
           cargar();
    }
    const onClick = e => {
        cargar();
    }
    const onRowClick = (item) => {
        props.onSelect(item);
        const estado = {nombre,datos,tema};
        const estadoStr = JSON.stringify(estado);
        localStorage.setItem(tema, estadoStr);
        document.body.click();
    }
    const cargar = async () => {
        const url = props.url + nombre;
        const res = await fetch(url);
        const datos = await res.json();
        setDatos(datos);
    }
    const ancho = props.ancho === undefined ? 400 : props.ancho;
    return(
        <div style={{ padding: 5 }} className="body">
            <div className="row">
                <div className="col-7">
                    <Input size="sm" type="text" className="form-control" placeholder="concepto" autoFocus value={nombre} onChange={e => setNombre(e.target.value)} onKeyPress={onKeyPress} />
                </div>
                <div className="col"><Button size="sm" color="success" onClick={onClick}>Buscar</Button></div>
            </div>
            <br/>
            <div style={{ height: 400, width: ancho, overflow: 'auto' }}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos.map((item, index) => {
                            return (
                                <tr key={index} onClick={() => onRowClick(item)}>
                                    <td>{item[props.campoId]}</td>
                                    <td>{item.N}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
export const BuscadorArticulos = (props) => {
    const [nombre,setNombre] = useState("");
    const [datos,setDatos] = useState([]);
    const [tema,setTema] = useState("estudios");
    useEffect(() => {
        const estado = localStorage.getItem(tema);
        if(estado !== null){
            const edo = JSON.parse(estado);
            setNombre(edo.nombre);
            setDatos(edo.datos);
        }
    },[props.tema]);
    const onKeyPress = e => {
        if (e.which === 13)
           cargar();
    }
    const onClick = e => {
        cargar();
    }
    const onRowClick = (item) => {
        props.onSelect(item);
        const estado = {nombre,datos,tema};
        const estadoStr = JSON.stringify(estado);
        localStorage.setItem(tema, estadoStr);
        document.body.click();
    }
    const cargar = async () => {
        const url = props.url + nombre;
        const res = await fetch(url);
        const datos = await res.json();
        setDatos(datos);
    }
    const ancho = props.ancho === undefined ? 400 : props.ancho;
    return(
        <div style={{ padding: 5 }} className="body">
            <div className="row">
                <div className="col-7">
                    <Input size="sm" type="text" className="form-control" placeholder="concepto" autoFocus value={nombre} onChange={e => setNombre(e.target.value)} onKeyPress={onKeyPress} />
                </div>
                <div className="col"><Button size="sm" color="success" onClick={onClick}>Buscar</Button></div>
            </div>
            <br/>
            <div style={{ height: 400, width: ancho, overflow: 'auto' }}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Nombre</th>
                            <th className='numeroCeldaMed'>Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos.map((item, index) => {
                            return (
                                <tr key={index} onClick={() => onRowClick(item)}>
                                    <td>{item.C}</td>
                                    <td>{item.N}</td>
                                    <td className='numeroCeldaMed'>{item.P.formato(2)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export const BuscadorPac = (props) => {
    const [apePat,setApePat] = useState("");
    const [apeMat,setApeMat] = useState("");
    const [nombre,setNombre] = useState("");
    const [datos,setDatos] = useState([]);
    const [tema,setTema] = useState("pac");

    useEffect(() => {
        const estado = localStorage.getItem("pac");
        if(estado !== null){
            const edo = JSON.parse(estado);
            setApePat(edo.apePat !== undefined ? edo.apePat : "");
            setApeMat(edo.apeMat !== undefined ? edo.apeMat : "");
            setNombre(edo.nombre !== undefined ? edo.nombre : "");
            setDatos(edo.datos);
        }
    },[]);
    const onKeyPress = e => {
        if (e.which === 13)
           cargar();
    }
    const onClick = e => {
        cargar();
    }
    const onRowClick = (item) => {
        props.onSelect(item);
        const estado = {nombre,datos,tema};
        const estadoStr = JSON.stringify(estado);
        localStorage.setItem(tema, estadoStr);
        document.body.click();
    }
    const cargar = async () => {
        let url = `${props.url}?nom=${nombre}&apePat=${apePat}&apeMat=${apeMat}`;
        const res = await fetch(url);
        const datos = await res.json();
        setDatos(datos);
    }
    const nuevo = () =>{
        props.onNuevo();
        document.body.click();
    }
    const ancho = props.ancho === undefined ? 400 : props.ancho;
    return(
        <div style={{ padding: 5 }} className="body">
            <div className="row">
                <div className='col'>
                <table>
                    <tr>
                        <td>
                            <Input size="sm" type="text" className="form-control celdaCodigo" placeholder="Ap.Pat." autoFocus value={apePat} onChange={e => setApePat(e.target.value)} onKeyPress={onKeyPress} />
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <Input size="sm" type="text" className="form-control celdaCodigo" placeholder="Ap.Mat." value={apeMat} onChange={e => setApeMat(e.target.value)} onKeyPress={onKeyPress} />
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <Input size="sm" type="text" className="form-control celdaCodigo" placeholder="nombre" value={nombre} onChange={e => setNombre(e.target.value)} onKeyPress={onKeyPress} />
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <Button size="sm" color="success" onClick={onClick}>Buscar</Button>
                        </td>
                    </tr>
                </table>
                </div>
            </div>
            <br/>
            <div style={{ height: 400, width: ancho, overflow: 'auto' }}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos.map((item, index) => {
                            return (
                                <tr key={index} onClick={() => onRowClick(item)}>
                                    <td>{item.N}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <Button size="sm" color="success" onClick={nuevo}><img src="/imagenes/add.png" alt="agrerar" title="agregar" /> Agregar</Button>
        </div>
    )
}
import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Container, Spinner, Input, Table, Button,FormGroup, Row, InputGroup,InputGroupAddon, Popover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import { AppContext } from './AppContext';
import {Buscador, BuscadorArticulos, BuscadorPac} from './Buscador';
import { Tabla } from './Tabla';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import Paciente from './Paciente';
import Cliente from './Cliente';
import Medico from './Medico';
import { Pacientes } from './Pacientes';
import Login from './Login';

export const Registro = (props) => {
    const edoGlobal = useContext(AppContext);
    let [fecha, setFecha] = useState(new Date().yyyymmdd('-'));
    let [fechaCorte, setFechaCorte] = useState(new Date().yyyymmdd('-'));
    let [fechaCitas,setFechaCitas] = useState(new Date());
    let [empresa, setEmpresa] = useState(2);
    let [almacen, setAlmacen] = useState(2);
    let [clave, setClave] = useState("");
    let [numero, setNumero] = useState(0);
    let [ctePub, setCtePub] = useState(null);
    let [nomEmpresa, setNomEmpresa] = useState("D.M.I.");
    let [urlUltTiket, setUrlUltTiket] = useState("");
    let [turnoAbierto,setTurnoAbierto] = useState(false);
    let [turno,setTurno] = useState({Acumulado:0, Fondo:0});
    let [credito, setCredito] = useState(false);
    let [articulo, setArticulo] = useState({});
    let [cliente, setCliente] = useState({});
    let [paciente, setPaciente] = useState({ApePat:'',ApeMat:'',Nombre:''});
    let [medico, setMedico] = useState({});
    let [horas, setHoras] = useState([6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21]);
    let [detalle, setDetalle] = useState([]);
    let [metodos, setMetodos] = useState([]);
    let [formas, setFormas] = useState([]);
    let [tiposPac, setTiposPac] = useState([]);
    let [usos, setUsos] = useState([]);
    let [listas, setListas] = useState([]);
    let [regimenes, setRegimenes] = useState([]);
    let [especialidades, setEspecialidades] = useState([]);
    let [citas, setCitas] = useState([]);
    let [idCita, setIdCita] = useState(0);
    let [juntar, setJuntar] = useState(false);
    let [facturar, setFacturar] = useState(false);
    let [idPac, setIdPac] = useState(0);
    let [idCte, setIdCte] = useState(0);
    let [idMed, setIdMed] = useState(0);
    let [codigo, setCodigo] = useState("");
    let [nombre, setNombre] = useState("");
    let [descrip, setDescrip] = useState("");
    let [hora, setHora] = useState(0);
    let [orden, setOrden] = useState("");
    let [nombreCte, setNombreCte] = useState("PUBLICO EN GENERAL");
    let [nombreMed, setNombreMed] = useState("");
    let [nombrePac, setNombrePac] = useState("");
    let [regImss, setRegImss] = useState("");
    let [lista, setLista] = useState("A");
    let [metodo, setMetodo] = useState("PUE");
    let [forma, setForma] = useState("01");
    let [uso, setUso] = useState("G03");
    let [medio, setMedio] = useState(0);
    let [metodoEnt, setMetodoEnt] = useState(0);
    let [nomMedio, setNomMedio] = useState("");
    let [medios, setMedios] = useState([]);
    let [metodosEnt, setMetodosEnt] = useState([]);

    let [tipoPac,setTipoPac] = useState(-1);
    let [nomTipoPac,setNomTipoPac] = useState("");
    let [popoverArtiOpen,setPopoverArtiOpen] = useState(false);
    let [popoverCteOpen,setPopoverCteOpen] = useState(false);
    let [popoverMedOpen,setPopoverMedOpen] = useState(false);
    let [modalFacturar,setModalFacturar] = useState(false);

    let [pacienteEnFactura,setPacienteEnFactura] = useState(true);
    let [citaObs, setCitaObs] = useState('');
    let [calle, setCalle] = useState('');
    let [colonia, setColonia] = useState('');
    let [rfc, setRfc] = useState('');
    let [ciudad, setCiudad] = useState('');
    let [municipio, setMunicipio] = useState('');
    let [estado, setEstado] = useState('');
    let [cp, setCP] = useState('');
    let [email, setEmail] = useState('');
    let [regimen, setRegimen] = useState('');

    const [cargando, setCargando] = useState(false);
    let [cant, setCant] = useState(0);
    let [total, setTotal] = useState(0);
    let [pago, setPago] = useState(0);
    let [pagoEfe, setPagoEfe] = useState(0);
    let [pagoTC, setPagoTC] = useState(0);
    let [pagoTD, setPagoTD] = useState(0);
    let [pagoTR, setPagoTR] = useState(0);
    let [cambio, setCambio] = useState(0);
    let [folioTC, setFolioTC] = useState("");
    let [folioTD, setFolioTD] = useState("");
    let [folioTR, setFolioTR] = useState("");

    let [impoGasto,setImpoGasto] = useState(0);
    let [conceGasto, setConceGasto] = useState("");
    let [tipoGasto,setTipoGasto] = useState(-1);
    let [tiposGasto,setTiposGasto] = useState([]);

    let [modalPago,setModalPago] = useState(false);
    let [modalPac,setModalPac] = useState(false);
    let [modalCte,setModalCte] = useState(false);
    let [modalMed,setModalMed] = useState(false);
    let [modalGasto,setModalGasto] = useState(false);
    let [modalLogin,setModalLogin] = useState(false);
    let [modalCitas,setModalCitas] = useState(false);

    let [solicitudes, setSolicitudes] = useState([]);
    let [modalSolicitudes,setModalSolicitudes] = useState(false);
    let [solicitud, setSolicitud] = useState({});  // pac descto solicitudes

    let [nombreTutor,setNombreTutor] = useState("");
    let [parentezcoTutor,setParentezcoTutor] = useState("");
    let [edadTutor,setEdadTutor] = useState(0);
    let [nombreRecogeResultados,setNombreRecogeResultados] = useState("");
    let [telefonoRecogeResultados,setTelefonoRecogeResultados] = useState("");

    let [esCotiza,setEsCotiza] = useState(false);

    useEffect(() => {
        cargarCatalogos();
        cargarCliente();
        setEmpresa(2);//edoGlobal.usuario.Empresa);
        if(edoGlobal.usuario.Empresa == 1){
            setAlmacen(2);
            setNomEmpresa("Analizate");
        }
    }, []);

    useEffect(() => {
        let c = 0;
        if(pago > total){
            c = pago - total;
            if(c > pagoEfe)
               c = pagoEfe;
        }
        setCambio(c);
    }, [pago]);

    useEffect(() => {
        setForma(credito ? "99" : "01");
        setMetodo(credito ? "PPD" : "PUE");
        if(credito)
           setPago(0);
    }, [credito]);

    function cambiarAlmacen(a){
        setAlmacen(a);
        //setEmpresa(2);
    }
    async function cargarCatalogos() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/factura/formasPago`;
        let resp = await fetch(url);
        const formas = await resp.json();
        url = `${edoGlobal.urlws}/factura/metodosPago`;
        resp = await fetch(url);
        const metodos = await resp.json();
        url = `${edoGlobal.urlws}/factura/usosCfdi`;
        resp = await fetch(url);
        const usos = await resp.json();
        url = `${edoGlobal.urlws}/factura/regimenes`;
        resp = await fetch(url);
        const reg = await resp.json();
        url = `${edoGlobal.urlws}/factura/listas/${almacen}?db=${empresa}`;
        resp = await fetch(url);
        const lst = await resp.json();
        url = `${edoGlobal.urlws}/turno/checarAbierto/${edoGlobal.usuario.Id}?db=${empresa}`;
        resp = await fetch(url);
        const tur = await resp.json();
        url = `${edoGlobal.urlws}/factura/tiposPaciente/${empresa}`;
        resp = await fetch(url);
        const tp = await resp.json();
        url = `${edoGlobal.urlws}/factura/tiposGastos/${empresa}`;
        resp = await fetch(url);
        const tg = await resp.json();
        url = `${edoGlobal.urlws}/factura/mediosEnterado/${empresa}`;
        resp = await fetch(url);
        const me = await resp.json();
        url = `${edoGlobal.urlws}/factura/metodosEntrega/${empresa}`;
        resp = await fetch(url);
        const mt = await resp.json();
        url = `${edoGlobal.urlws}/medico/especialidades/${empresa}`;
        resp = await fetch(url);
        const esp = await resp.json();
        setCargando(false);
        setTiposPac(tp);
        setFormas(formas);
        setUsos(usos);
        setTiposGasto(tg);
        setRegimenes(reg);
        setMetodos(metodos);
        setListas(lst);
        setTurno(tur);
        setMedios(me);
        setMetodosEnt(mt);
        setEspecialidades(esp);
        setTurnoAbierto(tur.Id != 0);
        //if(tur.Id == 0)
        //   abrirTurno();
    }
    async function seleccionarCita(c){
        setIdCita(c.I);
        idPac = c.Pac;
        idMed = c.Med;
        idCte = c.Cte;
        codigo = c.Cod;
        setCitaObs(c.Txt);
        setTipoPac(c.Tipo);
        setNomTipoPac(c.T);
        //setIdPac(c.Pac);
        //setCodigo(c.Cod);
        //setIdMed(c.Med);
        setModalCitas(false);
        await cargarCliente();
        await cargarPaciente();
        await cargarMedico();
        await cargarArticulo();
        if(c.Txt != "")
        Swal.fire('Observaciones de la cita',c.Txt,'info');
    }
    async function cargarCitas(){
        setCargando(true);
        let alm = almacen;
        let url = `${edoGlobal.urlws}/home/citasPendientes/${edoGlobal.usuario.Empresa}?alm=${alm}&fecha=${fechaCitas.yyyymmdd('-')}&depto=0`;
        let resp = await fetch(url);
        const datos = await resp.json();
        setCitas(datos);
        setCargando(false);
        setModalCitas(true);
    }

    function cambiaPago(t,v){
        let imp = Number(v);
        if(t == 'E'){
            setPagoEfe(imp);
            setPago(pagoTC + pagoTD + pagoTR + imp);
        }
        if(t == 'C'){
            setPagoTC(imp);
            setPago(pagoEfe + pagoTD + pagoTR+ imp);
        }
        if(t == 'D'){
            setPagoTD(imp);
            setPago(pagoEfe + pagoTC + pagoTR+ imp);
        }
        if(t == 'T'){
            setPagoTR(imp);
            setPago(pagoEfe + pagoTC + pagoTD+ imp);
        }
    }
    function cerrarTurno(){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cerrar el turno actual ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#008500',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async (result) => {
            if (!result.value)
               return;
            let url = `${edoGlobal.urlws}/turno/cerrar/${turno.Id}?db=${empresa}`;
            setCargando(true);
            let resp = await fetch(url);
            let res = await resp.text();
            setCargando(false);
            if(res == "ok")
               setTurnoAbierto(false);
            else
               Swal.fire('Atencion',res,'error');
        }); 
    }
    async function abrirTurno(){
        const { value: fondo } = await Swal.fire({
            title: 'Abrir turno de ' + edoGlobal.usuario.Nombre,
            input: 'text',
            inputLabel: 'Fondo inicial',
            inputPlaceholder: 'fondo inicial'
          })          
          if (fondo) {
            let url = `${edoGlobal.urlws}/turno/abrir?db=${empresa}&usuario=${edoGlobal.usuario.Id}&fondo=${fondo}`;
            setCargando(true);
            let resp = await fetch(url);
            setCargando(false);
            let t = await resp.json();
            setTurno(t);
            setTurnoAbierto(true);
        }
    }
    function pacSeleccionado(dato){
        // si hay descuento de solicitud y se cambia de paciente quitar descuento
        if(solicitud != null && solicitud.P != dato.Id){
            aplicarDescuento(0);
        }
        idPac = dato.Id;
        setPaciente(dato);
        setIdPac(idPac);
        setNombrePac(dato.Nombre + " " + dato.ApePat + " " + dato.ApeMat + "  Edad : " + dato.Edad + "  Sexo : " + dato.Sexo + "   Tel.: " + dato.Telefono);
        setRegImss(dato.RegImss);
        setModalPac(false);
    }
    async function cargarPaciente() {
        // si hay descuento de solicitud y se cambia de paciente quitar descuento
        if(solicitud != null && solicitud.P != idPac){
            aplicarDescuento(0);
        }
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/cargar/${idPac}?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        if(dato.Id == 0){
            setNombrePac("No registrado");
            setRegImss("");
            return;
        }
        setPaciente(dato);
        setIdPac(idPac);
        setNombrePac(dato.Nombre + " " + dato.ApePat + " " + dato.ApeMat + "  Edad : " + dato.Edad + "  Sexo : " + dato.Sexo + "   Tel.: " + dato.Telefono);
        setRegImss(dato.RegImss);
    }
    async function guardarPacienteActual() {
        let cod = "";
        if(detalle.length > 0)
           cod = detalle[0].Codigo;
        if(idPac == 0 || idMed == 0 || cod == ""){
            Swal.fire('Atencion',"se debe especificar paciente, medico y estudio", 'error');
            return;
        }
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/grabaPacienteActual/${idPac}?db=${empresa}&usu=${edoGlobal.usuario.Id}&med=${idMed}&cod=${cod}`;
        let resp = await fetch(url);
        const dato = await resp.text();
        setCargando(false);
        if(dato != "ok"){
            Swal.fire('Atencion', dato, 'error');
            return;
        }
    }
    async function cargarCliente() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/cliente/carga/${idCte}?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        if(dato.Empresa == 0){
            setNombreCte("No registrado");
            return;
        }
        setCredito(dato.EsCredito);
        setCliente(dato);
        if(ctePub == null)
           setCtePub(dato);
        lista = dato.ListaDMA;
        setIdCte(idCte);
        setLista(dato.ListaDMA);

        setCalle(dato.Calle);
        setColonia(dato.Colonia);
        setRfc(dato.Rfc);
        setCiudad(dato.Ciudad);
        setMunicipio(dato.Municipio);
        setEstado(dato.Estado);
        setCP(dato.CP);
        setEmail(dato.Email);
        setRegimen(dato.Regimen);
        setNombreCte(dato.Nombre);
    }
    async function cargarMedico() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/medico/cargar/${idMed}?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        if(dato.Clave == 0){
            setNombreMed("No registrado");
            return;
        }
        setMedico(dato);
        setIdMed(idMed);
        setNombreMed(dato.NombreCompleto);
    }
    function editarPac(){
        if(idPac == 0)
           return;
        setModalPac(true);
    }
    async function nuevoPac(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/cargar/-1?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        console.log(dato);
        setCargando(false);
        setIdPac(0);
        setNombrePac("NO REGISTRADO");
        setRegImss("");
        setPaciente(dato);
        setModalPac(true);
    }
    function pacienteGuardado(id){
        setModalPac(false);
        if(id > 0){
            idPac = id;
            setIdPac(id);
            cargarPaciente();
        }
    }
    function aplicarDescuento(pdescto){
        let tot = 0;
        for(let i = 0; i < detalle.length; i++){
            detalle[i].Descuento = 0;
            detalle[i].PDescto = pdescto;
            if(detalle[i].PDescto > 0)
               detalle[i].Descuento = Math.round(detalle[i].Importe * detalle[i].PDescto / 100,2);
            detalle[i].Total = detalle[i].Importe - detalle[i].Descuento;
            tot += detalle[i].Total;
        }
        setTotal(tot);
    }
    function aplicarDescuentoSol(d){
        setModalSolicitudes(false);
        setSolicitud(d);
        if(idPac != d.P){
            idPac = d.P;
            setIdPac(d.P);
            cargarPaciente();
        }
        if(detalle.length > 0)
          aplicarDescuento(d.D);
    }
    async function cargarSolicitudes(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/solicitudesDesctoConfirmadas/${almacen}?db=${empresa}`;
        let resp = await fetch(url);
        var s = await resp.json();
        setCargando(false);
        setSolicitudes(s);
        setModalSolicitudes(true);
    }
    async function solicitarDescuento(){
        if(idPac == 0){
            Swal.fire('Atencion',"se debe especificar el paciente", 'error');
            return;
        }
        if(cliente.ConApoyoClientes){
            Swal.fire('Atencion',"Este cliente no permite descuentos", 'error');
            return;
        }
        const { value: concepto } = await Swal.fire({
            title: 'Solicitud de descuento',
            input: 'text',
            inputLabel: 'Concepto',
            inputPlaceholder: 'concepto',
            inputValue: ''
          })
          if (concepto){
            solicitarDescuentoB(concepto);
          }
    }
    async function solicitarDescuentoB(concepto){
        let url = `${edoGlobal.urlws}/paciente/solicitarDescto`;
        let dato = {
            Empresa : empresa,
            Paciente : idPac,
            Almacen : almacen,
            Usuario : edoGlobal.usuario.Id,
            Concepto : concepto
        }
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) == "Er")
           Swal.fire('Atencion',res,'error');
        else{
           Swal.fire('Atencion',"Se guardo la solicitud de descuento",'success');
        }
    }
    function editarCte(){
        if(idCte == 0)
           return;
        setModalCte(true);
    }
    async function nuevoCte(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/cliente/cargar/-1?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        setIdCte(-1);
        setNombreCte("NO REGISTRADO");
        dato.Clave = -1;
        dato.Almacen = almacen;
        dato.Sector = 1;
        dato.ListaDMA = "A";
        setCliente(dato);
        setModalCte(true);
    }
    function clienteGuardado(id){
        setModalCte(false);
        if(id > 0){
            idCte = id;
            setIdCte(id);
            cargarCliente();
        }
    }
    function editarMed(){
        if(idMed > 1)
           setModalMed(true);
    }
    async function nuevoMed(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/medico/cargar/0?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        setIdMed(0);
        setNombreMed("NO REGISTRADO");
        dato.Clave = 0;
        dato.Almacen = almacen;
        dato.Empresa = empresa;
        setMedico(dato);
        setModalMed(true);
    }
    function medicoGuardado(id){
        setModalMed(false);
        if(id > 0){
            idMed = id;
            setIdMed(id);
            cargarMedico();
        }
    }
    async function cargarArticulo() {
        //setCargando(true);
        let url = `${edoGlobal.urlws}/articulo/cargarPrecio/${codigo}?db=${empresa}&alm=${almacen}&lista=${lista}&medico=${idMed}&cliente=${idCte}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        //setCargando(false);
        if(dato.Empresa == 0){
            setNombre("No registrado");
            return;
        }
        if(dato.Precio == 0){
            Swal.fire('Atencion','El Estudio' + dato.Nombre + ' no tiene precio en la lista ' + lista,'error');
            return;
        }
        //if(cliente.Descuento > 0)
        //   dato.Precio = dato.Precio - Math.round(dato.Precio * cliente.Descuento / 100);
        setCodigo(codigo);
        setArticulo(dato);
        setNombre(dato.Nombre);
        agregar(dato);
    }
    function agregar(dato){
        if(solicitud != null && solicitud.P == idPac)
           dato.PDescto = solicitud.D;
        setDescrip(dato.Descripcion);
        if(juntar)
          for(let i = 0; i < detalle.length; i++){
            if(detalle[i].Codigo == dato.Codigo && detalle[i].Precio == dato.Precio && detalle[i].PDescto == dato.PDescto){
                detalle[i].Cantidad++;
                detalle[i].Importe = detalle[i].Precio * detalle[i].Cantidad;
                detalle[i].Descuento = 0;
                if(detalle[i].PDescto > 0)
                   detalle[i].Descuento = Math.round(detalle[i].Importe * detalle[i].PDescto / 100,2);
                detalle[i].Total = detalle[i].Importe - detalle[i].Descuento;
                setCant(cant + 1);
                setTotal(total + dato.Total);
                return;
            }
          }
        let desc = 0;
        if(dato.PDescto > 0)
          desc =Math.round(dato.Precio * dato.PDescto / 100,2);
        detalle.push({Codigo: dato.Codigo, Nombre: dato.Nombre, Cantidad:1, Precio: dato.Precio, Importe: dato.Precio, Descripcion:dato.Descripcion, PDescto:dato.PDescto, Descuento : desc, Total:dato.Precio - desc});
        setCant(cant + 1);
        setTotal(total + dato.Precio - desc);
    }
    function renglonActual(r,i){
        setCodigo(r.Codigo);
        setNombre(r.Nombre);
        setDescrip(r.Descripcion);
    }
    function cambiarTipoPac(tp){
        setTipoPac(tp);
        if(tp == 4 || tp == 5)
           capturarNomTipoPac(tp == 4 ? "IMSS":"ISSSTE");
        else
           setNomTipoPac("");
    }
    async function capturarNomTipoPac(inst){
        const { value: clinica } = await Swal.fire({
            title: 'Numero de clinica',
            input: 'text',
            inputLabel: 'Numero de clinica',
            inputPlaceholder: ''
          })
          if (clinica)
             setNomTipoPac(inst + " " + clinica);
          else
             setNomTipoPac("");
    }
    function articuloSel(a){
        codigo = a.C;
        cargarArticulo();       
    }
    function clienteSel(c){
        idCte = c.C;
        cargarCliente();       
    }
    function medicoSel(c){
        idMed = c.I;
        cargarMedico();       
    }
    function iniciarRetiro(){
        setModalLogin(true);
    }
    async function retirar(u){
        let disponible = turno.Acumulado;
        if(!u.Mensajero){
            Swal.fire('Atencion','Los datos ingresados no son de un mensajero','error');
            return;
        }
        setModalLogin(false);
        const { value: retiro } = await Swal.fire({
            title: 'Importe del retiro (disponible : ' + disponible.formato() + ' )',
            input: 'text',
            inputLabel: 'Importe',
            inputPlaceholder: 'importe'
          })          
          if (retiro) {
            let importeRet = Number(retiro);
            if(importeRet > disponible){
                Swal.fire('Atencion','El importe del retiro (' + importeRet.formato() +  ') es mayor al importe disponible (' + disponible.formato() + ')','error');
                return;    
            }
            if(importeRet > 0)
               guardarGastoRetiro('R','GASTO',importeRet,0,u.Id)
        }
    }
    function guardarGasto(){
        setModalGasto(false);
        if(impoGasto > 0)
           guardarGastoRetiro('G',conceGasto,impoGasto,tipoGasto,0);
    }
    async function guardarGastoRetiro(tp, con, imp, tg, mensajero){
        let url = `${edoGlobal.urlws}/turno/guardarRetiro`;
        let dato = {
            Turno : turno.Id, Empresa : empresa,
            Tipo : tp, Concepto : con, Almacen:almacen,
            TipoGasto : tg, Mensajero : mensajero, Fecha:fecha,
            Importe:imp
        }
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) == "Er")
           Swal.fire('Atencion',res,'error');
        else{
           //Swal.fire('Atencion',"Se guardo el retiro " + res,'success');
           turno.Acumulado -= imp;
           setCambio(cambio + 1);
           setImpoGasto(0);
           setConceGasto("");
        }
    }
    function cobrar(){
        if(esCotiza){
            guardar();
            return;
        }
        if(tipoPac < 0){
            Swal.fire('Atencion','No se ha indicado el tipo de paciente','error');
            return;
        }
        if(detalle.length == 0){
            Swal.fire('Atencion','No hay estudios registrados','error');
            return;
        }
        if(idPac == 0){
            Swal.fire('Atencion','No se ha indicado el paciente','error');
            return;
        }
        if(idMed == 0){
            Swal.fire('Atencion','No se ha indicado el medico','error');
            return;
        }
        setModalPago(true);
    }
    function guardar(){
        let pagoTot = pagoEfe + pagoTC + pagoTD + pagoTR;
        let validar = !esCotiza;
        if (validar) {
            if (paciente.Edad > 17) {
                nombreTutor = "";
                parentezcoTutor = "";
                edadTutor = 0;
            } else if (nombreTutor == "" || parentezcoTutor == "" || edadTutor < 18) {
                if (nombreTutor == "" || parentezcoTutor == "")
                    Swal.fire('Atencion', 'El paciente es menor de edad y no se han indicado los datos del tutor', 'error');
                else
                    Swal.fire('Atencion', 'El tutor no puede ser menor de edad', 'error');
                return;
            }
            if (medio == 0 || (medio == 6 && nomMedio == "")) {
                Swal.fire('Atencion', 'No se ha indicado como se entero de nosotros', 'error');
                return;
            }
            if (metodoEnt == 0) {
                Swal.fire('Atencion', 'No se ha indicado el metodo para entrega de resultados', 'error');
                return;
            }
            if (!credito && pagoTot < total) {
                Swal.fire('Atencion', 'El importe del pago es menor al total del tiket', 'error');
                return;
            }
            if ((pagoTD > 0 && folioTD.length < 3) || (pagoTC > 0 && folioTC.length < 3) || (pagoTR > 0 && folioTR.length < 3)) {
                Swal.fire('Atencion', 'No se ha indicado el numero de autorizacion del cobro con tarjeta', 'error');
                return;
            }
        }
        /*
        if(this.state.formaPago === '?' || this.state.metodoPago === '?' || this.state.usoCfdi === '?' || this.state.cliente === 0){
            Swal.fire('Atencion','Falta de especificar algunos datos','success');
            return;
        }
        */
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let idSol = solicitud == null ? 0 : solicitud.I;
        let efe = 0;
        let det = [];
        for(let i = 0; i < detalle.length; i++) {
            det.push({
                cod: detalle[i].Codigo,
                can: detalle[i].Cantidad,
                pre: detalle[i].Precio,
                pdes: detalle[i].PDescto
            });
        }
        if(pagoEfe > 0)
           forma = "01";
        if(pagoTR > pagoEfe)
           forma = "03";
        if(pagoTC > pagoEfe)
           forma = "04";
        if(pagoTD > pagoEfe)
           forma = "28";
        if(credito)
           forma = "99";
        else
           efe = pagoEfe - cambio;
        if(medio != 6)  // si no es "otro", en nomMedio poner el nombre del medio seleccionado
           for(let j = 0; j < medios.length; j++)
              if(medios[j].I == medio)
                 nomMedio = medios[j].N;

        let json = JSON.stringify(det);
        let dato = {
            empresa,almacen, usuario:edoGlobal.usuario.Id, lista,
            metodo, forma, uso,pago,turno:turno.Id, cita: idCita,
            //tipoRel: this.state.tipoRel,
            //folioRel: this.state.folioRel,
            //ivaIncluido: this.state.ivaIncluido?'S':'N',
            //global:this.state.esGlobal?'S':'N',
            //fechaIni:this.state.fechaIni,
            //fechaFin:this.state.fechaFin,
            //periodicidad:this.state.periodicidad,
            cte: idCte, med: idMed, pac: idPac, tipoPac,
            hora, orden, cred:credito?"S":"N", cotiza: esCotiza ? "S":"N",
            efe:pagoEfe - cambio, tc :pagoTC, td:pagoTD, tr:pagoTR,
            folTC:folioTC, folTD:folioTD, folTR:folioTR,
            medio, metodoEnt, nomMedio, nomTipoPac, idSol,
            nombreTutor,parentezcoTutor,edadTutor, nombreRecogeResultados, telefonoRecogeResultados,
            s: json
        }
        let url = `${edoGlobal.urlws}/factura/wsguardar`;
        setCargando(true);
        json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0, 3) !== "Err"){
            let cve = res.substring(0,2);
            let num = res.substring(2,10);
            await Swal.fire('Atencion','se registro el tiket ' + cve + '-' + num,'success');
            if(efe > 0) // metodo == "PUE" && forma == "01")
               acumular(efe);
            setClave(cve);
            setNumero(num);
            if(esCotiza && paciente.Telefono == "6682224286") 
               enviarWS(cve,num,paciente.Telefono);      
            await enviarModalidad(cve,num);
            enviarEmail(cve,num);
            setModalPago(false);
            if(facturar)
               setModalFacturar(true);
            else
               iniciar();
        }
        else
           Swal.fire('Atencion',res,'error');
    }
    async function facturarTK(){
        let dir = calle;
        let pob = ciudad;
        if(colonia != "")
           dir = dir + " Col. " + colonia;
        if(municipio != "")
           pob = pob + ", " + municipio;
        if(estado != "")
           pob = pob + ", " + estado;
        let dato = {
            emp:empresa, alm:almacen, usu:edoGlobal.usuario.Id,
            cve:clave, num:numero, nom:nombreCte, dir, pob,
            cp, metodo, forma, uso, rfc, regimen, email,
            pacEnPDF : pacienteEnFactura ? "S":"N"
        }
        let url = `${edoGlobal.urlws}/factura/wsfacturarTK`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0, 3) !== "Err"){
            await Swal.fire('Atencion','se registro la factura ' + res,'success');
            //enviarEmail(res);
        }
        else
           Swal.fire('Atencion',res,'error');
        iniciar();
    }
    async function acumular(efe){
        let url = `${edoGlobal.urlws}/turno/acumular/${turno.Id}?db=${empresa}&importe=${efe}`;
        setCargando(true);
        let resp = await fetch(url);
        setCargando(false);
        let t = await resp.json();
        turno.Acumulado = t;
        setCambio(cambio + 1);
    }
    function imprimir(){
        if(urlUltTiket != "")
           window.open(urlUltTiket, '', '');
    }
    function imprimirOrden(){
        if(idPac == 0){
            Swal.fire('Atencion','No se ha indicado el paciente','error');    
            return;
        }
        if(idMed == 0){
            Swal.fire('Atencion','No se ha indicado el medico','error');    
            return;
        }
        if(detalle.length == 0){
            Swal.fire('Atencion','No hay estudios registrados','error');
            return;
        }
        let est = "";
        let sepa = "";
        for(let i = 0; i < detalle.length; i++) {
            est = est + sepa + detalle[i].Codigo;
            sepa=",";
        }
        const url = `${edoGlobal.urlws}/paciente/imprimirOrden/${empresa}?idPac=${idPac}&idMed=${idMed}&estudios=${est}`;
        window.open(url, '', '');
    }
    async function subirOrden(){
        if(idPac == 0){
            Swal.fire('Atencion','No se ha indicado el paciente','error');    
            return;
        }
        const { value: file } = await Swal.fire({
            title: 'Seleccione el archivo',
            input: 'file',
            inputAttributes: {
              accept: 'pdf/*',
              'aria-label': 'Seleccione archivo PDF'
            }
          });
          
          if (file) {
            setCargando(true);
            const data = new FormData()
            data.append('file', file);
            data.append('id', idPac);
            data.append('db', empresa);
            data.append('usu', edoGlobal.usuario.Id);
            let url = `${edoGlobal.urlws}/paciente/subirOrden`;
            const resp = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            setCargando(false);
            if(res.substring(0, 3) !== "Err")
               Swal.fire('Atencion',"Se registro la orden medica",'success');    
            else
               Swal.fire('Atencion',res,'error');    
          }        
    }
    async function enviarWS(cve,num,cel){
        let url = `https://www.estadoscuenta.isaerpweb.com/tw/tiket?db=${empresa}&alm=${almacen}&cve=${cve}&num=${num}&cel=${cel}`;
        let resp = await fetch(url);
    }
    async function enviarModalidad(cve,num){
        let url = `${edoGlobal.urlws}/paciente/enviarModalidad?db=${empresa}&alm=${almacen}&cve=${cve}&num=${num}`;
        setCargando(true);
        let resp = await fetch(url);
        setCargando(false);
        let res = await resp.text();
    }
    async function enviarEmail(cve,num){
        setUrlUltTiket(`${edoGlobal.urlws}/factura/tiketPDF?db=${empresa}&alm=${almacen}&cve=${cve}&num=${num}`);
        const { value: email } = await Swal.fire({
            title: 'Enviar tiket ' + cve + '-' + num + ' por email',
            input: 'email',
            inputLabel: 'direccion de email del paciente',
            inputPlaceholder: 'direccion de email del paciente'
          })          
          if (email) {
            let url = `${edoGlobal.urlws}/factura/tiketEmail?db=${empresa}&alm=${almacen}&cve=${cve}&num=${num}&email=${email}`;
            setCargando(true);
            let resp = await fetch(url);
            setCargando(false);
            let res = await resp.text();
            if(res == "ok")
               Swal.fire('Atencion','se envio el email a ' + email,'success');
            else
               Swal.fire('Atencion',res,'error');
        }
    }
    function cancelar(){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea salir sin guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                iniciar();
            }
        });
    }
    function iniciar(){
        setModalFacturar(false);
        setIdCita(0);
        if(esCotiza)
           setEsCotiza(false);
        setNombreRecogeResultados("");
        setTelefonoRecogeResultados("");
        setPacienteEnFactura(true);
        setNombreTutor("");
        setParentezcoTutor("");
        setEdadTutor(0);
        setSolicitud(null);
        setFacturar(false);
        setCredito(false);
        setHora(0);
        setCitaObs("");
        setLista("A");
        setDescrip("");
        setOrden("");
        setTipoPac(-1);
        setNomTipoPac("");
        setCant(0);
        setFolioTC("");
        setFolioTD("");
        setFolioTR("");
        setTotal(0);
        setPago(0);
        setPagoTC(0);
        setPagoTD(0);
        setPagoTR(0);
        setPagoEfe(0);
        setCambio(0);
        setIdPac(0);
        setIdMed(0);
        setIdCte(0);
        setMedio(0);
        setMetodoEnt(0);
        setNomMedio("");
        setCodigo("");
        setNombreCte("PUBLICO EN GENERAL");
        setNombreMed("");
        setNombrePac("");
        setRegImss("");
        setNombre("");
        setDetalle([]);
        setModalPago(false);
        
        setCalle(ctePub.Calle);
        setColonia(ctePub.Colonia);
        setRfc(ctePub.Rfc);
        setCiudad(ctePub.Ciudad);
        setMunicipio(ctePub.Municipio);
        setEstado(ctePub.Estado);
        setCP(ctePub.CP);
        setEmail(ctePub.Email);
        setRegimen(ctePub.Regimen);
    }
    function editarDescto(i){
        Swal.fire('Atencion',"La opcion para indicar descuento no esta dispobible",'error');
    }
    async function editarDesctoXXX(i){
        const { value: ptje } = await Swal.fire({
            title : 'Modificar % de descuento',
            input : 'text',
            inputLabel : '% de descuento',
            inputPlaceholder : '% descto.',
            inputValue: detalle[i].PDescto
          })          
          if (ptje) {
            if(Number.isInteger(Number(ptje))){
                let totAnt = detalle[i].Total;
                detalle[i].PDescto = Number(ptje);
                detalle[i].Descuento = 0;
                if(detalle[i].PDescto > 0)
                   detalle[i].Descuento = Math.round(detalle[i].Importe * detalle[i].PDescto / 100,2);
                detalle[i].Total = detalle[i].Importe - detalle[i].Descuento;
                setTotal(total + detalle[i].Total - totAnt);
            }
        }
    }
    function quitar(o,i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ quitar " + o.Nombre + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#008500',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                let imp = detalle[i].Total;
                let can = detalle[i].Cantidad;
                detalle.splice(i, 1);
                setCant(cant - can);
                setTotal(total - imp);
                setDescrip("");
            }
        });
    }
    function corte(){
        let url = `${edoGlobal.urlws}/home/corte?emp=${empresa}&alm=${almacen}&fec=${fechaCorte}`;
        window.open(url, '', '');    
    }
    return (
        <div>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }
            <div style={{padding:5, display: turnoAbierto ? 'none' : 'inline'}}>
                <Button size="sm" color="warning" onClick={abrirTurno}>Abrir turno</Button>
            </div>
            <div style={{ display: turnoAbierto ? 'inline' : 'none' }}>
                <div style={{ padding: 5 }}>
                    <Row>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>
                                    <Input size="sm" type="select" value={almacen} onChange={e => cambiarAlmacen(e.target.value)} style={{width:'100px'}}>
                                        <option value={2}>Jesus</option>
                                    </Input>
                                </td>
                                <td className='celdaCh'><span>Paciente</span></td>
                                <td className='celdaCodigo'>
                                    <InputGroup>
                                        <Input size="sm" type="text" value={idPac} onChange={e => setIdPac(e.target.value)} onKeyPress={(e) => { if (e.which == 13) cargarPaciente(); }} />
                                        <InputGroupAddon addonType="append">
                                            &nbsp;
                                            <Button outline size="sm" color="secondary" onClick={() => setModalPac(true)}>
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup>
                                        <Input size="sm" disabled value={nombrePac} />
                                        <InputGroupAddon addonType="append">
                                            &nbsp;
                                            <Button size="sm" outline color="secondary" onClick={nuevoPac} >
                                                <img src="/imagenes/add.png" alt="nuevo" title="nuevo paciente" />
                                            </Button>
                                            &nbsp;
                                            <Button size="sm" outline color="secondary" onClick={editarPac} >
                                                <img src="/imagenes/edit.png" alt="editar" title="editar" />
                                            </Button>
                                            &nbsp;
                                            <Button outline size="sm" color="secondary" onClick={subirOrden} >
                                                <img src="/imagenes/explorar.png" alt="orden" title="subir orden medica" />
                                            </Button>
                                            &nbsp;
                                            <Button outline size="sm" color="secondary" onClick={imprimirOrden}>
                                                <img src="/imagenes/printer.png" alt="orden" title="imprimir orden medica" />
                                            </Button>
                                            &nbsp;
                                            <Button outline size="sm" color="secondary" onClick={solicitarDescuento}>
                                                <img src="/imagenes/campana.png" alt="orden" title="solicitar descuento" />
                                            </Button>
                                            &nbsp;
                                            <Button outline size="sm" color="secondary" onClick={cargarSolicitudes}>
                                                <img src="/imagenes/tabla.png" alt="orden" title="solicitudes de descuento" />
                                            </Button>
                                        </InputGroupAddon>
                                        &nbsp;&nbsp;
                                        <input size="sm" placeholder="Reg.IMSS" disabled value={regImss} style={{ width: 200 }} />
                                    </InputGroup>
                                </td>
                                <td className='celdaCodigo'>
                                    <Input size="sm" placeholder="No. Orden" value={orden} onChange={(e) => setOrden(e.target.value)} />
                                </td>
                                <td>
                                    <Input size="sm" type="select" value={tipoPac} onChange={e => cambiarTipoPac(e.target.value)} style={{width:'150px'}}>
                                        <option value={-1}>Especifique</option>
                                        {tiposPac.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </td>
                                <td><span>{nomTipoPac}</span></td>
                                <td>&nbsp;&nbsp;<b>{(turno.Acumulado + turno.Fondo).formato()}</b></td>
                            </tr>
                        </table>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td className='celdaCh'><span>Cliente</span></td>
                                <td className='celdaCodigo'>
                                    <InputGroup>
                                        <Input size="sm" type="text" value={idCte} onChange={e => setIdCte(e.target.value)} onKeyPress={(e) => { if (e.which == 13) cargarCliente(); }} />
                                        <InputGroupAddon addonType="append">
                                            &nbsp;
                                            <Button size="sm" outline color="secondary" id="btnBuscarCte">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={popoverCteOpen} target="btnBuscarCte" toggle={() => setPopoverCteOpen(!popoverCteOpen)} >
                                                <PopoverBody>
                                                    <Buscador campoId="C" onSelect={clienteSel} url={edoGlobal.urlws + "/cliente/lista/" + empresa + "?filtro="} tema="ctes" />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup>
                                    &nbsp;<Badge color="warning"><br/>&nbsp;{lista}&nbsp;</Badge>&nbsp;
                                        <Input size="sm" disabled value={nombreCte} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" outline color="secondary" onClick={nuevoCte} >
                                                <img src="/imagenes/add.png" alt="nuevo" title="nuevo cliente" />
                                            </Button>
                                            &nbsp;
                                            <Button size="sm" outline color="secondary" onClick={editarCte} >
                                                <img src="/imagenes/edit.png" alt="editar" title="editar" />
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </td>
                                <td className='celdaCh'><span>Medico</span></td>
                                <td className='celdaCodigo'>
                                    <InputGroup>
                                        <Input size="sm" type="text" value={idMed} onChange={e => setIdMed(e.target.value)} onKeyPress={(e) => { if (e.which == 13) cargarMedico(); }} />
                                        <InputGroupAddon addonType="append">
                                            &nbsp;
                                            <Button outline size="sm" color="secondary" id="btnBuscarMed">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={popoverMedOpen} target="btnBuscarMed" toggle={() => setPopoverMedOpen(!popoverMedOpen)} >
                                                <PopoverBody>
                                                    <Buscador campoId="I" onSelect={medicoSel} url={edoGlobal.urlws + "/medico/lista/" + empresa + "?filtro="} tema="meds" />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </td>
                                <td>
                                <InputGroup>
                                        <Input size="sm" disabled value={nombreMed} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" outline color="secondary" onClick={nuevoMed} >
                                                <img src="/imagenes/add.png" alt="nuevo" title="nuevo medico" />
                                            </Button>
                                            &nbsp;
                                            <Button size="sm" outline color="secondary" onClick={editarMed} >
                                                <img src="/imagenes/edit.png" alt="editar" title="editar" />
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </td>
                                <td style={{ width: 60 }}>
                                    <Input size="sm" type="select" value={hora} onChange={(e) => setHora(e.target.value)}>
                                        <option value={0}>Hora</option>
                                        {horas.map((o, i) =>
                                            <option key={i} value={o}>{o}</option>
                                        )}
                                    </Input>
                                </td>
                                <td>Compromiso</td>
                                <td>
                                    <Button size="sm" outline color="secondary" onClick={cargarCitas} ><img src="/imagenes/citas.png" /> Citas</Button>
                                    <span>&nbsp;&nbsp;</span>
                                    <Button size="sm" outline color="secondary" onClick={() => setModalGasto(true)}><img src="/imagenes/add.png" /> Gasto</Button>
                                    <span>&nbsp;&nbsp;</span>
                                    <Button size="sm" outline color="secondary" onClick={iniciarRetiro} ><img src="/imagenes/add.png" /> Retiro</Button>
                                </td>
                                <td>
                                    <Input type="checkbox" checked={esCotiza} onChange={(e) => setEsCotiza(e.target.checked)} />
                                    <b>Presupuesto</b>
                                </td>
                            </tr>
                        </table>
                    </Row>
                    <Row>
                        <table>
                            <tr>
                                <td><span>Codigo</span></td>
                                <td>
                                    <InputGroup>
                                        <Input size="sm" type="text" value={codigo} onChange={e => setCodigo(e.target.value)} onKeyPress={(e) => { console.log(e); if (e.which == 13) cargarArticulo(); }}  />
                                        <InputGroupAddon addonType="append">
                                            &nbsp;
                                            <Button outline size="sm" color="secondary" id="btnBuscarArti">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={popoverArtiOpen} target="btnBuscarArti" toggle={() => setPopoverArtiOpen(!popoverArtiOpen)} >
                                                <PopoverBody>
                                                    <BuscadorArticulos onSelect={articuloSel} url={edoGlobal.urlws + "/articulo/listaPrecios/" + empresa + "?lista=" + lista + "&alm=" + almacen + "&filtro="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </td>
                                <td>{nombre}</td>
                            </tr>
                        </table>
                    </Row>
                </div>
                <span>
                    Observaciones de la cita {citaObs}
                </span>
                <div style={{ height: 350, overflow: 'auto' }}>
                    <Table size="sm" striped hover>
                        <thead>
                            <th className='celdaCodigo'>Codigo</th>
                            <th>Nombre</th>
                            <th className='numeroCeldaCh'>Cant.</th>
                            <th className='numeroCeldaMed'>Precio</th>
                            <th className='numeroCeldaMed'>%Desc</th>
                            <th className='numeroCeldaMed'>Importe</th>
                            <th></th>
                            <th></th>
                        </thead>
                        <tbody>
                            {detalle.map((o, i) => {
                                return (
                                    <tr key={i} onClick={() => renglonActual(o, i)}>
                                        <td>{o.Codigo}</td>
                                        <td>{o.Nombre}</td>
                                        <td className='numeroCeldaCh'>{o.Cantidad}</td>
                                        <td className='numeroCeldaMed'>{o.Precio.formato(2)}</td>
                                        <td className='numeroCeldaMed'><Button size="sm" color="warning" outline onClick={() => editarDescto(i)}>{o.PDescto}</Button></td>
                                        <td className='numeroCeldaMed'>{o.Total.formato(2)}</td>
                                        <td className='celdaCh'><img src="/imagenes/edit.png" title="editar" /></td>
                                        <td className='celdaCh'><img onClick={() => quitar(o, i)} src="/imagenes/menos.png" title="quitar" /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <Table size="sm" striped hover>
                    <tbody>
                        <tr>
                            <th className='celdaCodigo'>{nomEmpresa}</th>
                            <td>
                                <Button size="sm" color="success" outline onClick={cobrar}>Guardar</Button>
                                &nbsp;&nbsp;
                                <Button size="sm" color="danger" outline onClick={cancelar}>Cancelar</Button>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <Input type="checkbox" checked={juntar} onChange={(e) => setJuntar(e.target.checked)} />
                                <span>Juntar estudios</span>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <Input type="checkbox" checked={credito} onChange={(e) => setCredito(e.target.checked)} />
                                <span>Credito</span>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <Input type="checkbox" checked={facturar} onChange={(e) => setFacturar(e.target.checked)} />
                                <span>Facturar</span>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <input size="sm" type="date" value={fechaCorte} onChange={(e) => setFechaCorte(e.target.value)} />
                                &nbsp;&nbsp;
                                <Button size="sm" outline color="secondary" onClick={corte}>Corte</Button>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <Button size="sm" outline color="success" title="imprimir ultimo ticket" onClick={imprimir}><img src="/imagenes/printer.png" />Tiket</Button>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <Button size="sm" outline color="danger" onClick={cerrarTurno}>Cerrar turno</Button>
                            </td>
                            <th className='numeroCeldaCh'>{cant}</th>
                            <th className='numeroCeldaMed'></th>
                            <th className='numeroCeldaMed'>{total.formato(2)}</th>
                            <th>
                            <Button size="sm" color="warning" outline onClick={guardarPacienteActual}>Consentimiento</Button>
                            </th>
                        </tr>
                    </tbody>
                </Table>
                <span><b>{nombre}</b> - </span><span className='rojo'>{descrip}</span>
            </div>
            <Modal size="lg" isOpen={modalPago} toggle={()=> setModalPago(false)}>
                <ModalHeader>Pagar  <b>{total.formato()}</b></ModalHeader>
                <ModalBody>
                    <Row>
                        <div className="col">
                        <div>
                            <FormGroup >
                                <span>Metodo de pago</span>
                                <Input size="sm" type="select" value={metodo} disabled={credito} onChange={e => setMetodo(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {metodos.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup >
                                <span>Forma de pago</span>
                                <Input size="sm" type="select" value={forma} disabled={credito} onChange={e => setForma(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {formas.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup >
                                <span>Uso</span>
                                <Input size="sm" type="select" value={uso} onChange={e => setUso(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {usos.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup >
                                <span>Como se entero de nosotros</span>
                                <Input size="sm" type="select" value={medio} onChange={e => setMedio(e.target.value)}>
                                    {medios.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup >
                                <span>Metodo de entrega de resultados</span>
                                <Input size="sm" type="select" value={metodoEnt} onChange={e => setMetodoEnt(e.target.value)}>
                                    {metodosEnt.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        </div>
                        <div className="col">
                            <br/>
                        <table>
                                <tr>
                                    <td>Efectivo</td>
                                    <td><Input size="sm" value={pagoEfe} disabled={credito} onChange={(e)=>cambiaPago('E', e.target.value)} /></td>
                                    <td><span>&nbsp;&nbsp;&nbsp;Folio Autorizacion</span></td>
                                </tr>
                                <tr>
                                    <td>T.Credito</td>
                                    <td><Input size="sm" value={pagoTC} disabled={credito} onChange={(e)=>cambiaPago('C', e.target.value)} /></td>
                                    <td><Input size="sm" value={folioTC} disabled={credito} onChange={(e)=>setFolioTC(e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td>T.Debito</td>
                                    <td><Input size="sm" value={pagoTD} disabled={credito} onChange={(e)=>cambiaPago('D', e.target.value)} /></td>
                                    <td><Input size="sm" value={folioTD} disabled={credito} onChange={(e)=>setFolioTD(e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td>Transferencia</td>
                                    <td><Input size="sm" value={pagoTR} disabled={credito} onChange={(e)=>cambiaPago('T', e.target.value)} /></td>
                                    <td><Input size="sm" value={folioTR} disabled={credito} onChange={(e)=>setFolioTR(e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td>Pago</td>
                                    <td><Input size="sm" value={pago} disabled={credito} onChange={(e)=>setPago(e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td>Cambio</td>
                                    <td><Input size="sm" value={cambio.formato()} /></td>
                                </tr>
                                <tr>
                                    <td>Cual</td>
                                    <td colspan="2"><Input size="sm" value={nomMedio} onChange={(e)=>setNomMedio(e.target.value)}/></td>
                                </tr>
                            </table>                            
                        </div>
                    </Row>
                    <h5>Persona autorizada para recoger resultados</h5>
                    <Row>
                        <div className='col-5'>
                            <span>Nombre</span>
                            <Input size="sm" value={nombreRecogeResultados}  onChange={(e)=>setNombreRecogeResultados(e.target.value)} />
                        </div>
                        <div className='col-3'>
                            <span>Telefono</span>
                            <Input size="sm" value={telefonoRecogeResultados}  onChange={(e)=>setTelefonoRecogeResultados(e.target.value)} />
                        </div>
                    </Row>
                    {paciente.Edad < 18 &&
                    <Row>
                        <div className='col-5'>
                            <span>Nombre del tutor</span>
                            <Input size="sm" value={nombreTutor}  onChange={(e)=>setNombreTutor(e.target.value)} />
                        </div>
                        <div className='col-5'>
                            <span>Parentezco del tutor</span>
                            <Input size="sm" value={parentezcoTutor}  onChange={(e)=>setParentezcoTutor(e.target.value)} />
                        </div>
                        <div className='col-2'>
                            <span>Edad del tutor</span>
                            <Input size="sm" value={edadTutor}  onChange={(e)=>setEdadTutor(e.target.value)} />
                        </div>
                    </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button size="sm" color="success" outline onClick={guardar}>Guardar</Button>
                        &nbsp;&nbsp;
                        <Button size="sm" color="danger" outline onClick={()=>setModalPago(false)}>Cancelar</Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={false} toggle={()=> setModalPac(false)}>
                <ModalHeader>
                    <h6>{idPac == 0 ?<span>Nuevo paciente</span> :<span>editar paciente</span>}</h6>
                </ModalHeader>
                <ModalBody>
                    <Paciente paciente={paciente} cerrar={pacienteGuardado} setCargando={setCargando} empresa={empresa}/>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={modalCte} toggle={()=> setModalCte(false)}>
                <ModalHeader>
                <h6>{idCte == 0 ?<span>Nuevo cliente</span> :<span>editar cliente</span>}</h6>
                </ModalHeader>
                <ModalBody>
                    <Cliente cliente={cliente} cerrar={clienteGuardado} setCargando={setCargando} regimenes={regimenes} listas={listas} empresa={empresa}/>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={modalMed} toggle={()=> setModalMed(false)}>
                <ModalHeader>
                <h6>{idMed == 0 ?<span>Nuevo medico</span> :<span>editar medico</span>}</h6>
                </ModalHeader>
                <ModalBody>
                    <Medico medico={medico} cerrar={medicoGuardado} setCargando={setCargando} especialidades={especialidades} empresa={empresa}/>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={modalPac} toggle={() => setModalPac(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Pacientes pac={idPac != 0 ? paciente : null} sel={true} pacSeleccionado={pacSeleccionado} empresa={empresa}/>
                </ModalBody>
            </Modal>
            <Modal size="sm" isOpen={modalLogin} toggle={() => setModalLogin(false)}>
                <ModalBody>
                    <Login iniciar={retirar} empresa={empresa} pedirEmpresa={false}/>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={modalCitas} toggle={() => setModalCitas(false)}>
                <ModalBody>
                    <div style={{ height: 500, overflow: 'auto' }}>
                        <Table size="sm" striped hover>
                            <thead>
                                <tr>
                                    <td>Hora</td>
                                    <td className='celdaNombre'>Paciente</td>
                                    <td className='celdaNombre'>Estudio</td>
                                    <td style={{ width: '30px' }}></td>
                                </tr>
                            </thead>
                            <tbody>
                                {citas.map((o, i) =>
                                    <tr key={i}>
                                        <td>{o.H}</td>
                                        <td>{o.P}</td>
                                        <td>{o.E}</td>
                                        <td><img src="/imagenes/ok.png" alt="seleccionar" title="seleccionar" onClick={()=> seleccionarCita(o)} /></td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={modalGasto} toggle={() => setModalGasto(false)}>
                <ModalHeader>Nuevo gasto</ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            Concepto
                            <Input style={{ height: 200 }} type="textarea" value={conceGasto} onChange={(e) => setConceGasto(e.target.value)} />
                        </Row>
                        <Row>
                            <div className='col-2'>
                                <span>Importe</span>
                                <Input size="sm" value={impoGasto} onChange={(e)=> setImpoGasto(e.target.value)} />
                            </div>
                            <div className='col-5'>
                                <span>Tipo</span>
                                <Input type="select" size="sm" value={tipoGasto} onChange={(e)=> setTipoGasto(e.target.value)}>
                                    <option value={-1}>Especifique</option>
                                    {tiposGasto.map((o,i)=><option value={o.I} key={i}>{o.N}</option>)}
                                </Input>
                            </div>
                            <div className='col-1'></div>
                            <div>
                                <br/>
                                <Button size="sm" color="success" outline onClick={guardarGasto}>Guardar</Button>
                            </div>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={modalFacturar} >
                <ModalHeader>
                    <h6>Facturar {clave}-{numero}</h6>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='col-9'>
                            <FormGroup >
                                <span>Nombre</span>
                                <Input size="sm" value={nombreCte} onChange={e => setNombreCte(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col-3'>
                            <FormGroup >
                                <span>R.F.C.</span>
                                <Input size="sm" value={rfc} onChange={e => setRfc(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Direccion</span>
                                <Input size="sm" value={calle} onChange={e => setCalle(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col-2'>
                            <FormGroup >
                                <span>C.P.</span>
                                <Input size="sm" value={cp} onChange={e => setCP(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Colonia</span>
                                <Input size="sm" value={colonia} onChange={e => setColonia(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Ciudad</span>
                                <Input size="sm" value={ciudad} onChange={e => setCiudad(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Municipio</span>
                                <Input size="sm" value={municipio} onChange={e => setMunicipio(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Estado</span>
                                <Input size="sm" value={estado} onChange={e => setEstado(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Regimen</span>
                                <Input size="sm" type="select" value={regimen} onChange={e => setRegimen(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {regimenes.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className='col-4'>
                            <FormGroup >
                                <span>Uso</span>
                                <Input size="sm" type="select" value={uso} onChange={e => setUso(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {usos.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Metodo de pago</span>
                                <Input size="sm" type="select" value={metodo} onChange={e => setMetodo(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {metodos.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Forma de pago</span>
                                <Input size="sm" type="select" value={forma} onChange={e => setForma(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {formas.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <Input type="checkbox" checked={pacienteEnFactura} onChange={(e) => setPacienteEnFactura(e.target.checked)} />
                                        <span>Imprimir nombre del paciente en factura</span>
                    </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className='col'>
                    <Button color="success" size="sm" outline onClick={facturarTK}>Aceptar</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button color="danger" size="sm" outline onClick={iniciar}>Cancelar</Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalSolicitudes} toggle={()=> setModalSolicitudes(false)}>
                <ModalHeader>Solicitudes de descuento</ModalHeader>
                <ModalBody>
                <div style={{ height: 350, overflow: 'auto' }}>
                    <Table size="sm" striped hover>
                        <thead>
                            <th>Paciente</th>
                            <th className='numeroCeldaCh'>%Desc</th>
                            <th className='celdaCodigo'>Status</th>
                            <th></th>
                        </thead>
                        <tbody>
                            {solicitudes.map((o, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{o.N}</td>
                                        <td className='numeroCeldaCh'>{o.D}</td>
                                        <td className='celdaCodigo'>{o.S}</td>
                                        <td className='celdaCh'>
                                            {o.T == 2 ?
                                            <img onClick={() => aplicarDescuentoSol(o)} src="/imagenes/ok.png" title="seleccionar" />
                                            :
                                            null
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button size="sm" color="danger" outline onClick={()=>setModalSolicitudes(false)}>Cerrar</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}
import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Input, Spinner, CardHeader, CardBody, Button, Table, Container, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';

export const Resultados = (props) => {
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [usuario, setUsuario] = useState(1);
    let [empresa, setEmpresa] = useState(2);
    let [almacen, setAlmacen] = useState(2);
    let [almacenes, setAlmacenes] = useState([{I:1,N:"DMI"}]);
    let [datos, setDatos] = useState([]);
    let [nombre, setNombre] = useState("");
    let [fecha, setFecha] = useState(new Date().yyyymmdd('-'));

    /*
    useEffect(() => {
        cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/factura/tipos/${empresa}`;
        let resp = await fetch(url);
        let datos = await resp.json();
        setClaves(datos);
        setCargando(false);
    }
    */
    async function cargarDatos(){
        let url = `${edoGlobal.urlws}/paciente/listaResultados/${empresa}?alm=${almacen}&fecha=${fecha}&nombre=${nombre}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setDatos(datos);
        setCargando(false);
    }
    return(
        <div>
            <table>
                <tr>
                <td>Almacen</td>
                    <td>
                        <Input size="sm" type="select" value={almacen} onChange={e => setAlmacen(e.target.value)} style={{ width: '100px' }}>
                            <option value={1}>Rosario</option>
                            <option value={2}>Jesus</option>
                        </Input>
                    </td>
                    <td>&nbsp;&nbsp;Del dia</td>
                    <td><Input size="sm" type="date" value={fecha} onChange={(e) => setFecha(e.target.value)} /></td>
                    <td>&nbsp;&nbsp;</td>
                    <td>&nbsp;&nbsp;</td>
                    <td>&nbsp;&nbsp;Nombre del paciente</td>
                    <td><Input size="sm" value={nombre} onChange={(e) => setNombre(e.target.value)} /></td>
                    <td>&nbsp;&nbsp;</td>
                    <td>&nbsp;&nbsp;</td>
                    <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarDatos}><img src="/imagenes/ok.png" title="cargar datos" /></Button></td>
                </tr>
            </table>
            <div style={{ height: 450, overflow: 'auto' }}>
                <Table size="sm" hover striped>
                    <thead>
                        <tr>
                            <th >Fecha</th>
                            <th>Paciente</th>
                            <th>Estudio</th>
                            <th>Telefono</th>
                            <th>Metodo entrega</th>
                            <th>Aut. recoger resultados</th>
                            <th>Interpretado</th>
                            <th className='celdaCh'></th>
                            <th className='celdaCh'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos.map((o, i) => <tr key={i}>
                            <td>{o.F}</td>
                            <td>{o.P}</td>
                            <td>{o.E}</td>
                            <td>{o.T}</td>
                            <td>{o.M}</td>
                            <td>{o.A}</td>
                            <td><input type="checkbox" checked={o.I === "S"} /></td>
                            <td><img src="/imagenes/email.png" title="enviar email" /></td>
                            <td><img src="/imagenes/whatsapp.png" title="enviar por whatsApp" /></td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
    )
}